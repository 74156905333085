<template>
  <b-card title="Upload File">
    <b-button
      variant="outline-primary"
      @click="$router.push(`/shipment/create?pickup_id=${$route.query.pickup_id}`)"
    >
      Create New Shipment
    </b-button>
    <validation-observer ref="simpleRules">
      <b-form>
        <div class="demo-spacing-0">
          <b-alert
            v-if="errors"
            show
            variant="danger"
          >
            <h4 class="alert-heading">
              Errors
            </h4>
            <div class="alert-body">
              <div
                v-for="(v, k) in errors"
                :key="k"
              >
                <p
                  v-for="error in v"
                  :key="error"
                  class="text-sm"
                >
                  {{ error }}
                </p>
              </div>
            </div>
          </b-alert>
        </div>
        <b-row class="mt-1">
          <b-col md="6">
            <b-form-group
              label="File"
            >
              <validation-provider
                #default="{ errors }"
                name="File"
                rules="required"
              >
                <b-form-file
                  id="file"
                  ref="file"
                  v-model="file"
                  accept=".xlsx"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- submit button -->
          <b-col md="5" />
          <b-col md="6">
            <b-button
              ref="submit"
              type="submit"
              variant="primary"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
            <b-button
              type="button"
              variant="warning"
              @click.prevent="cancel"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col md="6">
            <h4>File template</h4>
            <ul>
              <li>Download the file to can upload shipment in one click</li>
              <li>Don't change or delete the header columns</li>
              <li>Leave AWB input blank for system generator</li>
              <li>Leave serial number blank to attach item for last record has serial number</li>
            </ul>
            <b-button
              type="button"
              variant="warning"
              @click.prevent="downloadFile"
            >
              Download template file
            </b-button>
          </b-col>
        </b-row>
        <b-overlay
          :show="busy"
          no-wrap
          @hidden="onHidden"
          @shown="onShown"
        >
          <template v-slot:overlay>
            <div
              v-if="processing"
              class="text-center p-4 bg-primary text-light rounded"
            >
              <UploadCloudIcon />
              <div class="mb-3">
                Processing... <br> please wait this may take a few minutes
              </div>
              <!--              <b-progress
                              min="1"
                              max="20"
                              :value="counter"
                              variant="success"
                              height="3px"
                              class="mx-n4 rounded-0"
                            />-->
            </div>
            <div
              v-else
              ref="dialog"
              aria-labelledby="form-confirm-label"
              aria-modal="false"
              class="text-center p-3"
              role="dialog"
              tabindex="-1"
            >
              <p><strong id="form-confirm-label">Are you sure?</strong></p>
              <div class="d-flex">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  class="mr-3"
                  variant="outline-danger"
                  @click="onCancel"
                >
                  Cancel
                </b-button>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-success"
                  @click="onOK"
                >
                  OK
                </b-button>
              </div>
            </div>
          </template>
        </b-overlay>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { required } from '@core/utils/validations/validations'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BAlert,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormFile,
  BFormGroup,
  BOverlay,
  BRow,
  VBModal,
} from 'bootstrap-vue'
import { debounce } from 'lodash'
import Ripple from 'vue-ripple-directive'
import { UploadCloudIcon } from 'vue-feather-icons'
import axios from '@/libs/axios'

export default {
  name: 'UploadFileVue',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BAlert,
    BFormFile,
    BOverlay,
    UploadCloudIcon,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      client_id: '',
      file: null,
      clients: [],
      required,
      errors: null,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
    }
  },
  beforeDestroy() {
    this.clearInterval()
  },
  methods: {
    validationForm() {
      // this.form.client_id = this.form.client_id.id
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.onSubmit()
        }
      })
    },
    cancel() {
      this.$router.push('/shipment')
    },
    searchClient(search, loading) {
      if (search) {
        loading(true)
        this.search(loading, search, this)
      }
    },
    search: debounce((loading, search, vm) => {
      axios.post('/clients/search', {
        /* filters: [
              {field: 'client_no', 'operator': '=', value: search}
          ], */
        search: { value: search },
      }).then(res => {
        // eslint-disable-next-line no-param-reassign
        vm.clients = res.data.data
        loading(false)
      })
    }, 350),
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
    },
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.processing = false
      this.busy = true
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.counter = 1
      this.processing = true
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('pickup_id', this.$route.query.pickup_id ? this.$route.query.pickup_id : null)
      axios.post('/shipments/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(resp => {
        if (resp) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Data is submitted successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push('/shipment')
        }
      }).catch(err => {
        this.errors = err.response.data.errors
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.message,
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
        this.$nextTick(() => {
          // eslint-disable-next-line
          this.busy = this.processing = false
        })
      })
      /* this.clearInterval()
      this.interval = setInterval(() => {
        if (this.counter < 20) {
          this.counter += 1
        } else {
          this.clearInterval()

        }
      }, 350) */
    },
    downloadFile() {
      window.location.href = `${process.env.VUE_APP_BACK_URL}/downloadFile`
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
</style>
